import React, { Component } from 'react';
import moment from 'moment';
import fr from "moment/locale/fr";
import { default as functions } from './functions';
import { mapping, light as basicTheme } from '@eva-design/eva';
import { Grid, Col, Row } from "react-native-easy-grid";
import { AppState, StyleSheet, View, ScrollView, TouchableHighlight } from 'react-native';
import { ApplicationProvider, IconRegistry, Icon, TopNavigation, TopNavigationAction, Layout, Modal, Button, Input, Text, Divider, Spinner } from '@ui-kitten/components';
import { BarChart, PieChart } from 'react-native-chart-kit';
import DataTable from 'react-data-table-component';
import RadioForm from 'react-native-simple-radio-button';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { style } from 'dom-helpers';

moment.updateLocale('fr', fr);

const initialState = {
  page: 'home',
  isLoading: false,
  examID: null,
  studentID: null,
  firstname: null,
  lastname: null,
  admin: {
    examsList: [],
    examsInfos: null,
    overlayIsVisible: false,
    isStats: false,
    isPie: false,
    idPie: 1,
    examActionLoading: [],
    stats: {
      bar: null,
      pie: null,
      raw: null
    },
    add: {
      examTitle: null,
      examGroupe: 1,
      examNbQuestions: 1,
      examAnswers: null
    }
  },
  exam: {
    nbQuestions: null,
    question: null,
    answer: "o"
  }
}

const answerForm = React.createRef();
var getListInterval;
var getStatsInterval;
var ltnr;

export default class App extends Component {
  constructor() {
    super();
    this.state = initialState;
  }

  componentDidMount() {
    ltnr = AppState.addEventListener('change', (nextAppState) => {
      if (nextAppState.match(/inactive|background/) && this.state.examID != "Omarb" && this.state.examID != null) {
        this.disconnect();
      }
    });
    window.onbeforeunload = (event) => {
      if (this.state.examID != "Omarb" && this.state.examID != null) {
        this.disconnect();
      }
    };
  }

  componentDidUpdate() {
    //EXAM
    if (this.state.page == 'exam' && this.state.exam.answer == 'o') {
      answerForm.current.updateIsActiveIndex(-1);
    }
  }

  componentWillUnmount() {
    if (ltnr != undefined) {
      ltnr.remove();
    }
    if (this.state.page != 'admin') {
      clearInterval(getListInterval);
    }
  }

  goBackHome() {
    this.setState(initialState);
  }

  connect() {
    this.setState({ isLoading: true });
    fetch('https://exam.belkheiri.net/api/connect', {
      method: 'POST', body: JSON.stringify({
        examID: this.state.examID,
        studentID: this.state.studentID
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => response.json()).then((data) => {
      if (data.isConnected) {
        if (data.isAdmin) {
          fetch('https://exam.belkheiri.net/api/getList', {
            method: 'GET'
          }).then((response) => response.json()).then((getExamsList) => {
            this.setState(prevState => ({ admin: { ...prevState.admin, examsList: getExamsList.list } }));
            //ADMIN
            getListInterval = setInterval(() => {
              fetch('https://exam.belkheiri.net/api/getList', {
                method: 'GET'
              }).then((response) => response.json()).then((getExamsList) => {
                this.setState(prevState => ({ admin: { ...prevState.admin, examsList: getExamsList.list } }));
              });
              if (this.state.page != 'admin') {
                clearInterval(getListInterval);
              }
            }, 600);
            this.setState({ isLoading: false });
            this.setState({ page: 'admin' });
          }).catch((error) => {
            this.setState({ isLoading: false });
            NotificationManager.error(error.toString(), 'Error');
          });
        } else {
          this.setState({ isLoading: false });
          this.setState({ page: 'exam', firstname: data.prenom.charAt(0).toUpperCase() + data.prenom.toLowerCase().slice(1), lastname: data.nom.toUpperCase(), exam: { nbQuestions: data.nbQuestions, question: data.question, answer: "o" } });
        }
      } else {
        this.setState({ isLoading: false });
        NotificationManager.error(data.logCodes[0], 'Error');
      }
    }).catch((error) => {
      this.setState({ isLoading: false });
      NotificationManager.error(error.toString(), 'Error');
    });
  }

  disconnect() {
    fetch('https://exam.belkheiri.net/api/disconnect', {
      method: 'POST', body: JSON.stringify({
        studentID: this.state.studentID
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      keepalive: true
    }).then((response) => response.json()).then((data) => {
      if (data.isDisconnected) {
        this.goBackHome();
      } else {
        NotificationManager.error(data.logCodes[0], 'Error');
      }
    });
  }

  submit() {
    this.setState({ isLoading: true });
    fetch('https://exam.belkheiri.net/api/submit', {
      method: 'POST', body: JSON.stringify({
        examID: this.state.examID,
        studentID: this.state.studentID,
        question: this.state.exam.question,
        answer: this.state.exam.answer
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => response.json()).then((data) => {
      if (data.isSubmited) {
        if (data.isLast) {
          this.disconnect();
        } else {
          this.setState({ exam: { nbQuestions: this.state.exam.nbQuestions, question: this.state.exam.question + 1, answer: "o" } });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
        NotificationManager.error(data.logCodes[0], 'Error');
      }
    });
  }

  actionOnExam(action, examID, i) {
    var tempExamActionLoading = this.state.admin.examActionLoading;
    tempExamActionLoading[i] = true;
    this.setState(prevState => ({ admin: { ...prevState.admin, examActionLoading: tempExamActionLoading } }));
    switch (action) {
      case 'createExam':
        this.setState({ isLoading: true });
        fetch('https://exam.belkheiri.net/api/createExam', {
          method: 'POST', body: JSON.stringify({
            title: this.state.admin.add.examTitle,
            examGroupe: this.state.admin.add.examGroupe,
            nbQuestions: this.state.admin.add.examNbQuestions,
            answers: this.state.admin.add.examAnswers
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then((response) => response.json()).then((data) => {
          if (data.isCreated) {
            this.setState({ isLoading: false });
            this.setState(prevState => ({ admin: { ...prevState.admin, overlayIsVisible: false } }));
          } else {
            this.setState({ isLoading: false });
            NotificationManager.error(data.logCodes[0], 'Error');
          }
        });
        break;
      case 'startExam':
      case 'finishExam':
      case 'deleteExam':
        fetch('https://exam.belkheiri.net/api/actionOnExam', {
          method: 'POST', body: JSON.stringify({
            action: action,
            examID: examID
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then((response) => response.json()).then((data) => {
          if (data.isDone) {
            var tempExamActionLoading = this.state.admin.examActionLoading;
            tempExamActionLoading[i] = false;
            this.setState(prevState => ({ admin: { ...prevState.admin, examActionLoading: tempExamActionLoading } }));
          } else {
            var tempExamActionLoading = this.state.admin.examActionLoading;
            tempExamActionLoading[i] = false;
            this.setState(prevState => ({ admin: { ...prevState.admin, examActionLoading: tempExamActionLoading } }));
            NotificationManager.error(data.logCodes[0], 'Error');
          }
        });
        break;
      default:
        var tempExamActionLoading = this.state.admin.examActionLoading;
        tempExamActionLoading[i] = false;
        this.setState(prevState => ({ admin: { ...prevState.admin, examActionLoading: tempExamActionLoading } }));
        NotificationManager.error(data.logCodes[0], 'Error');
        break;
    }
  }

  downloadExam(data) {
    window.open('https://exam.belkheiri.net/api/downloadExam/' + data[0].examID, '_blank');
  }

  showStats(examID) {
    if (examID != null) {
      this.setState(prevState => ({ isLoading: true, admin: { ...prevState.admin, overlayIsVisible: true, isStats: true, isPie: false } }));
      getStatsInterval = setInterval(() => {
        fetch('https://exam.belkheiri.net/api/getStats/' + examID, {
          method: 'GET'
        }).then((response) => response.json()).then((data) => {
          if (data.tStats != null) {
            var total = this.state.admin.examsInfos.examAnswers.filter(item => item.state != 'ABS').length;
            var bar = {
              labels: ["Réponses justes\nm = " + (data.tStats.nbATTrue / total).toFixed(2), "Réponses fausses\nm = " + (data.tStats.nbATFalse / total).toFixed(2), "Réponse nulles\nm = " + (data.tStats.nbATNull / total).toFixed(2)],
              datasets: [{ data: [data.tStats.nbATTrue, data.tStats.nbATFalse, data.tStats.nbATNull] }]
            }
            var pie = [];
            for (var i in data.dStats) {
              pie.push([
                {
                  name: "Réponse A",
                  nbA: data.dStats[i].nbAnswerA,
                  color: "#CDCDCD"
                },
                {
                  name: "Réponse B",
                  nbA: data.dStats[i].nbAnswerB,
                  color: "#999999"
                },
                {
                  name: "Réponse C",
                  nbA: data.dStats[i].nbAnswerC,
                  color: "#666666"
                },
                {
                  name: "Réponse D",
                  nbA: data.dStats[i].nbAnswerD,
                  color: "#333331"
                },
                {
                  name: "Aucune réponse",
                  nbA: data.dStats[i].nbANull,
                  color: "#000000"
                }
              ]);
            }
            this.setState(prevState => ({ isLoading: false, admin: { ...prevState.admin, stats: { bar: bar, pie: pie, raw: data.dStats } } }));
          } else {
            NotificationManager.error('Error', 'Error');
            this.setState(prevState => ({ isLoading: false }));
          }
        });
        if (this.state.page != 'admin' || !this.state.admin.isStats || !this.state.admin.overlayIsVisible) {
          clearInterval(getStatsInterval);
        }
      }, 600);
    }
  }

  showPie(idPie) {
    if (idPie != null) {
      this.setState(prevState => ({ admin: { ...prevState.admin, idPie: idPie } }));
      this.setState(prevState => ({ admin: { ...prevState.admin, overlayIsVisible: true, isStats: true, isPie: true } }));
    }
  }

  render() {
    if (this.state.page != 'admin' && this.state.page != 'exam') {
      return (
        <ApplicationProvider mapping={mapping} theme={basicTheme}>
          <IconRegistry icons={EvaIconsPack} />
          <NotificationContainer />
          <Layout style={[styles.container, styles.center]}>
            <Grid>
              <Row size={1} />
              <Row style={styles.center} size={2}>
                <Layout>
                  <Text category='h1' style={{ fontSize: 70 }}>Qexam</Text>
                </Layout>
              </Row>
              <Row style={styles.center} size={1}>
                <Layout>
                  <Input
                    placeholder="Numéro de l'examen"
                    icon={(style) => (<Icon {...style} name='list' />)}
                    onChangeText={value => this.setState({ examID: value })}
                    disabled={this.state.isLoading}
                  />
                  <Text>{'\n'}</Text>
                  <Input
                    placeholder="Numéro d'inscription"
                    icon={(style) => (<Icon {...style} name='credit-card-outline' />)}
                    onChangeText={value => this.setState({ studentID: value })}
                    disabled={this.state.isLoading}
                  />
                </Layout>
              </Row>
              <Row style={styles.center} size={1}>
                <Layout>
                  {(!this.state.isLoading) ? (
                    <Button
                      status='primary'
                      icon={(style) => (<Icon {...style} name='lock' />)}
                      onPress={() => this.connect()}>
                      Connexion
                    </Button>
                  ) : (
                    <Spinner size='giant' />
                  )}
                </Layout>
              </Row>
              <Row size={2} />
            </Grid>
            <View style={[styles.center, { position: 'absolute', bottom: 10 }]}><Text>© 2019-{new Date().getFullYear()}   CSorigins</Text></View>
          </Layout>
        </ApplicationProvider>
      );
    } else if (this.state.page == 'admin') {
      if (this.state.admin.examsList != undefined && this.state.admin.examsList != null && this.state.admin.examsList != []) {
        return (
          <ApplicationProvider mapping={mapping} theme={basicTheme}>
            <IconRegistry icons={EvaIconsPack} />
            <NotificationContainer />
            <TopNavigation
              title='Qexam'
              subtitle='Admin panel'
              style={{ backgroundColor: 'rgb(51, 102, 255)' }}
              titleStyle={{ color: '#fff' }}
              subtitleStyle={{ color: '#C5CEE0' }}
              alignment='center'
              leftControl={<TopNavigationAction
                icon={(style) => (<Icon {...style} name='home-outline' fill='#fff' />)}
                onPress={() => this.goBackHome()} />}
            />{(Array.isArray(this.state.admin.examsList) && this.state.admin.examsList.length) ? (
              <Layout style={styles.container} level='4'>
                <Grid>
                  <Col size={1} />
                  <Col size={8}>
                    <Layout style={styles.container} level='1'>
                      <ScrollView>{this.state.admin.examsList.map((data, i) => {
                        return (
                          <TouchableHighlight
                            key={data.examID}
                            activeOpacity={1}
                            underlayColor={'gainsboro'}
                            onPress={() => this.setState(prevState => ({ admin: { ...prevState.admin, examsInfos: data } }))}>
                            <View>
                              <View style={{ flexDirection: 'row', marginHorizontal: 10, marginVertical: 5, padding: 10 }}>
                                <View style={{ alignSelf: "center" }}>
                                  <View style={{ flexDirection: 'row' }}>
                                    <View style={{ marginEnd: 5, alignSelf: "center", height: 10, width: 10, borderRadius: 50, backgroundColor: (data.examState == 'ready') ? 'orange' : (data.examState == 'onGoing') ? 'green' : 'red' }}></View>
                                    <Text style={{ fontWeight: "bold", fontSize: 15 }}>{data.title} - {(data.examState == 'ready') ? 'prêt' : (data.examState == 'onGoing') ? 'En cours' : 'terminé'}</Text>
                                  </View>
                                  <Text style={{ fontStyle: 'italic', color: 'darkgray' }}>Examen numéro {data.examID} - groupe {data.examGroupe}</Text>
                                  <Text style={{ fontStyle: 'italic', color: 'darkgray' }}>{(new Date() - new Date(data.examDate) <= 24 * 60 * 60 * 1000) ? moment(data.examDate).fromNow() : moment(data.examDate).format('DD/MM/YYYY à HH:mm')}</Text>
                                </View>
                                {(!this.state.admin.examActionLoading[i]) ? (
                                  <View style={{ flexDirection: 'row', alignSelf: "center", position: 'absolute', right: 0 }}>
                                    <View style={{ display: (data.examState == 'finished') ? 'none' : 'flex', flexDirection: 'row', marginEnd: 5 }}>
                                      <Divider style={{ display: (data.examState == 'finished') ? 'none' : 'flex', alignSelf: "center", height: 45, width: 1, marginEnd: 5, backgroundColor: 'lightgray' }} />
                                      <Button
                                        appearance='ghost'
                                        icon={(style) => (<Icon {...style}
                                          name={(data.examState == 'ready') ? 'arrow-right' : 'square'}
                                          height={30} width={30}
                                          fill={(data.examState == 'ready') ? 'green' : 'blue'} />)}
                                        onPress={() => this.actionOnExam((data.examState == 'ready') ? 'startExam' : 'finishExam', data.examID, i)}
                                      />
                                    </View>
                                    <Divider style={{ alignSelf: "center", height: 45, width: 1, marginEnd: 5, backgroundColor: 'lightgray' }} />
                                    <Button
                                      appearance='ghost'
                                      icon={(style) => (<Icon {...style} name='trash' height={30} width={30} fill='red' />)}
                                      onPress={() => {
                                        if (confirm('Voulez-vous vraiment supprimer l\'examen numéro ' + data.examID + ' ?\nLa suppression sera définitive et irréversible.')) {
                                          this.actionOnExam('deleteExam', data.examID, i);
                                        }
                                      }}
                                    />
                                  </View>
                                ) : (
                                  <View style={{ flexDirection: 'row', alignSelf: "center", position: 'absolute', right: 20 }}>
                                    <Spinner />
                                  </View>
                                )}
                              </View>
                              <Divider style={{ backgroundColor: 'lightgray' }} />
                            </View>
                          </TouchableHighlight>
                        );
                      })}</ScrollView>
                      <TouchableHighlight
                        activeOpacity={1}
                        underlayColor={'gainsboro'}
                        onPress={() => this.setState(prevState => ({ admin: { ...prevState.admin, overlayIsVisible: true } }))}>
                        <View>
                          <Divider style={{ backgroundColor: 'lightgray' }} />
                          <View style={{ flexDirection: 'row', padding: 20, justifyContent: "center" }}>
                            <Icon
                              name='plus'
                              fill='rgb(51, 102, 255)'
                              height={25}
                              width={25}
                              style={{ alignSelf: "center" }}
                            />
                            <Text style={{ color: 'rgb(51, 102, 255)', marginStart: 5, alignSelf: "center", fontSize: 15, marginBottom: 2 }}>Créer un examen</Text>
                          </View>
                        </View>
                      </TouchableHighlight>
                      <Divider style={{ alignSelf: 'center', position: 'absolute', height: 100 + '%', width: 1, right: 0, backgroundColor: 'lightgray' }} />
                    </Layout>
                  </Col>
                  <Col size={8}>
                    {(this.state.admin.examsInfos != null) ? (
                      <Layout style={styles.container} level='1'>
                        <Grid>
                          <Row size={2} style={{ margin: 15 }}>
                            <Col size={4} style={{ justifyContent: "center" }}>
                              <Text style={{ fontWeight: "bold" }}>Examen n°{this.state.admin.examsInfos.examID}: {this.state.admin.examsInfos.title}</Text>
                              <Text style={{ fontWeight: "bold" }}><View style={{ marginEnd: 5, height: 10, width: 10, borderRadius: 50, backgroundColor: (this.state.admin.examsInfos.examState == 'ready') ? 'orange' : (this.state.admin.examsInfos.examState == 'onGoing') ? 'green' : 'red' }}></View>{(this.state.admin.examsInfos.examState == 'ready') ? 'Prêt' : (this.state.admin.examsInfos.examState == 'onGoing') ? 'En cours' : 'Terminé'}</Text>
                              <Text>{(new Date() - new Date(this.state.admin.examsInfos.examDate) <= 24 * 60 * 60 * 1000) ? moment(this.state.admin.examsInfos.examDate).fromNow() : moment(this.state.admin.examsInfos.examDate).format('DD/MM/YYYY à HH:mm')}</Text>
                            </Col>
                            <Col size={4} style={{ justifyContent: "center" }}>
                              <Text style={{ fontWeight: "bold" }}>Groupe {this.state.admin.examsInfos.examGroupe} - {this.state.admin.examsInfos.examAnswers.length} étudiants</Text>
                              <Text>dont {this.state.admin.examsInfos.examAnswers.filter(item => item.state != 'ABS').length} présents ({Math.round(this.state.admin.examsInfos.examAnswers.filter(item => item.state != 'ABS').length / this.state.admin.examsInfos.examAnswers.length * 100)}%)</Text>
                              <Text>dont {this.state.admin.examsInfos.examAnswers.filter(item => item.state == 'ABS').length} absents ({Math.round(this.state.admin.examsInfos.examAnswers.filter(item => item.state == 'ABS').length / this.state.admin.examsInfos.examAnswers.length * 100)}%)</Text>
                            </Col>
                            <Col size={4} style={{ justifyContent: "center" }}>
                              <Text style={{ fontWeight: "bold" }}>Notes:</Text>
                              <Text>moyenne: {(this.state.admin.examsInfos.examState == 'finished') ? functions.average(this.state.admin.examsInfos.examAnswers) : 'n/a'}</Text>
                              <Text>médianne: {(this.state.admin.examsInfos.examState == 'finished') ? functions.median(this.state.admin.examsInfos.examAnswers) : 'n/a'}</Text>
                            </Col>
                            <Col style={styles.center}>
                              <Button
                                appearance='ghost'
                                size='small'
                                icon={(style) => (<Icon {...style} name='refresh' height={25} width={25} fill='black' />)}
                                onPress={() => this.setState(prevState => ({ admin: { ...prevState.admin, examsInfos: this.state.admin.examsList.find(item => item.examID == this.state.admin.examsInfos.examID) } }))}
                              />
                              <Button
                                appearance='ghost'
                                size='small'
                                icon={(style) => (<Icon {...style} name='download' height={25} width={25} fill='black' />)}
                                onPress={() => this.downloadExam(this.state.admin.examsInfos.examAnswers)}
                              />
                              <Button
                                appearance='ghost'
                                size='small'
                                icon={(style) => (<Icon {...style} name='pie-chart-outline' height={25} width={25} fill='black' />)}
                                onPress={() => this.showStats(this.state.admin.examsInfos.examID)}
                              />
                            </Col>
                          </Row>
                          <Divider style={{ backgroundColor: 'lightgray' }} />
                          <Row size={15}>
                            <View style={{ width: '100%', height: '100%' }}>
                              <DataTable
                                columns={[
                                  {
                                    name: 'Numéro d\'étudiant',
                                    selector: 'student.studentID',
                                    id: 0,
                                    sortable: true,
                                    format: (row) => <Text><View style={{ height: 10, width: 10, borderRadius: 50, backgroundColor: (row.student.isConnected) ? 'green' : 'red' }}></View> {row.student.studentID}</Text>
                                  },
                                  {
                                    name: 'Nom',
                                    selector: 'student.lastname',
                                    id: 1,
                                    sortable: true,
                                    format: (row) => <Text>{row.student.lastname}</Text>
                                  },
                                  {
                                    name: 'Prénom',
                                    selector: 'student.firstname',
                                    id: 2,
                                    sortable: true,
                                    format: (row) => <Text>{row.student.firstname}</Text>
                                  },
                                  {
                                    name: 'Groupe',
                                    selector: 'student.groupe',
                                    id: 3,
                                    sortable: true,
                                    format: (row) => <Text>{row.student.groupe}</Text>
                                  },
                                  {
                                    name: 'Réponse justes',
                                    selector: 'aTrue',
                                    id: 4,
                                    sortable: true,
                                    format: (row) => <Text>{row.aTrue}</Text>
                                  },
                                  {
                                    name: 'Réponse fausses',
                                    selector: 'aFalse',
                                    id: 5,
                                    sortable: true,
                                    format: (row) => <Text>{row.aFalse}</Text>
                                  },
                                  {
                                    name: 'Réponse nulles',
                                    selector: 'aNull',
                                    id: 6,
                                    sortable: true,
                                    format: (row) => <Text>{row.aNull}</Text>
                                  },
                                  {
                                    name: 'Note',
                                    selector: 'note',
                                    id: 7,
                                    sortable: true,
                                    format: (row) => (row.note != null) ? (row.state != 'ABS') ? row.note + '/20' : 'ABS' : 'n/a',
                                  }
                                ]}
                                data={this.state.admin.examsInfos.examAnswers}
                                defaultSortFieldId={0}
                                defaultSortAsc={true}
                                noHeader
                                highlightOnHover
                                pagination
                                paginationComponentOptions={{
                                  rowsPerPageText: 'Lignes par page:',
                                  rangeSeparatorText: 'parmi',
                                  selectAllRowsItem: true,
                                  selectAllRowsItemText: 'Tout',
                                }}
                              />
                            </View>
                          </Row>
                        </Grid>
                      </Layout>
                    ) : (
                      <Layout style={[styles.container, styles.center]} level='1'>
                        <Text appearance='hint'>Aucun examen sélectionné</Text>
                      </Layout>
                    )}
                  </Col>
                  <Col size={1} />
                </Grid>
                <Modal
                  visible={this.state.admin.overlayIsVisible}
                  style={[{ height: (this.state.admin.isStats) ? 800 : 600, width: (this.state.admin.isStats) ? 700 : 500 }]}
                  backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                  onBackdropPress={() => this.setState(prevState => ({ admin: { ...prevState.admin, overlayIsVisible: false, isStats: false, isPie: false } }))}>
                  {(!this.state.admin.isStats) ? (
                    <Layout style={[styles.container, styles.center]}>
                      <Grid>
                        <Row size={1}>
                          <Layout style={[styles.container, styles.center]}>
                            <Text category='h5'>Ajouter un examen</Text>
                          </Layout>
                        </Row>
                        <Row size={2}>
                          <Layout style={[styles.container]}>
                            <Input
                              placeholder="Titre de l'examen"
                              icon={(style) => (<Icon {...style} name='hash' />)}
                              onChangeText={text => this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examTitle: text } } }))}
                              disabled={this.state.isLoading}
                            />
                            <Text>{"\n"}</Text>
                            <View style={[{ flexDirection: 'row', flexWrap: 'wrap' }, styles.center]}>
                              <Text style={{ fontWeight: "bold" }}>Groupe: {this.state.admin.add.examGroupe + '\t'}</Text>
                              <Button
                                style={{ height: 40, borderTopRightRadius: 0, borderBottomRightRadius: 0, marginRight: -1 }}
                                appearance='outline'
                                icon={(style) => (<Icon {...style} name='minus' />)}
                                onPress={() => {
                                  if (this.state.admin.add.examGroupe >= 2) {
                                    this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examGroupe: this.state.admin.add.examGroupe - 1 } } }))
                                  }
                                }}
                                disabled={this.state.isLoading}
                              />
                              <Button
                                style={{ height: 40, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                appearance='outline'
                                icon={(style) => (<Icon {...style} name='plus' />)}
                                onPress={() => {
                                  if (this.state.admin.add.examGroupe <= 19) {
                                    this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examGroupe: this.state.admin.add.examGroupe + 1 } } }))
                                  }
                                }}
                                disabled={this.state.isLoading}
                              />
                            </View>
                            <Text>{"\n"}</Text>
                            <View style={[{ flexDirection: 'row', flexWrap: 'wrap' }, styles.center]}>
                              <Grid>
                                <Row size={3} style={[styles.center, { marginBottom: 10 }]}>
                                  <Text style={{ fontWeight: "bold" }}>Nombre de questions:</Text>
                                </Row>
                                <Row size={1}>
                                  <Input
                                    placeholder="Nombre de questions"
                                    value={this.state.admin.add.examNbQuestions}
                                    onChangeText={text => {
                                      if (parseInt(text)) {
                                        if (parseInt(text) >= 1 && parseInt(text) <= 300) {
                                          this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examNbQuestions: parseInt(text) } } }));
                                        }
                                      } else {
                                        this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examNbQuestions: 1 } } }));
                                      }
                                    }}
                                    disabled={this.state.isLoading}
                                  />
                                  <Button
                                    style={{ height: 40, borderTopRightRadius: 0, borderBottomRightRadius: 0, marginRight: -1 }}
                                    appearance='outline'
                                    icon={(style) => (<Icon {...style} name='minus' />)}
                                    onPress={() => {
                                      if (this.state.admin.add.examNbQuestions >= 2) {
                                        this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examNbQuestions: parseInt(this.state.admin.add.examNbQuestions) - 1 } } }))
                                      }
                                    }}
                                    disabled={this.state.isLoading}
                                  />
                                  <Button
                                    style={{ height: 40, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                    appearance='outline'
                                    icon={(style) => (<Icon {...style} name='plus' />)}
                                    onPress={() => {
                                      if (this.state.admin.add.examNbQuestions <= 299) {
                                        this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examNbQuestions: parseInt(this.state.admin.add.examNbQuestions) + 1 } } }))
                                      }
                                    }}
                                    disabled={this.state.isLoading}
                                  />
                                </Row>
                              </Grid>
                            </View>
                            <Text>{"\n"}</Text>
                            <Input
                              placeholder="Réponses justes"
                              icon={(style) => (<Icon {...style} name='list' />)}
                              onChangeText={text => this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examAnswers: text } } }))}
                              disabled={this.state.isLoading}
                            />
                          </Layout>
                        </Row>
                        <Row size={1}>
                          <Layout style={[styles.container, styles.center]}>
                            {(!this.state.isLoading) ? (
                              <Button
                                style={{ marginTop: -15 }}
                                appearance='outline'
                                icon={(style) => (<Icon {...style} name='checkmark-circle-outline' />)}
                                onPress={() => this.actionOnExam('createExam', null)}
                                disabled={this.state.isLoading}>
                                Créer{this.state.isLoading}
                              </Button>
                            ) : (
                              <Spinner size='giant' />
                            )}
                          </Layout>
                        </Row>
                      </Grid>
                    </Layout>
                  ) : (
                    <Layout style={[styles.container, styles.center]}>
                      <Grid>
                        {(!this.state.admin.isPie) ? (
                          <Row size={1}>
                            {(!this.state.isLoading) ? (
                              <View style={{ marginTop: 20 }}>
                                <BarChart
                                  data={this.state.admin.stats.bar}
                                  width={480}
                                  height={300}
                                  withVerticalLabels
                                  fromZero
                                  showValuesOnTopOfBars
                                  chartConfig={{
                                    backgroundColor: '#000000',
                                    backgroundGradientFrom: '#fff',
                                    backgroundGradientTo: '#fff',
                                    fillShadowGradient: '#696969',
                                    fillShadowGradientOpacity: 255,
                                    color: (opacity = 255) => `rgba(0, 0, 0, ${opacity})`,
                                    strokeWidth: 50,
                                    barPercentage: 1,
                                    decimalPlaces: 0
                                  }}
                                  style={{ marginBottom: 30, paddingTop: 25, paddingLeft: 25, borderRadius: 15, shadowColor: "#000", shadowOffset: { width: 0, height: 5, }, shadowOpacity: 0.34, shadowRadius: 6.27, elevation: 10 }}
                                />
                                <DataTable
                                  columns={[
                                    {
                                      name: 'Numéro de question',
                                      selector: 'nbQuestion',
                                      id: 0,
                                      sortable: true,
                                      format: (row) => <Text>Question n°{row.nbQuestion}</Text>
                                    },
                                    {
                                      name: 'Réponses fausses',
                                      selector: 'nbAFalse',
                                      id: 1,
                                      sortable: true,
                                      format: (row) => <Text>{row.nbAFalse}</Text>
                                    },
                                    {
                                      name: 'Réponses justes',
                                      selector: 'nbATrue',
                                      id: 2,
                                      sortable: true,
                                      format: (row) => <Text>{row.nbATrue}</Text>
                                    },
                                    {
                                      name: 'Réponses nulles',
                                      selector: 'nbANull',
                                      id: 3,
                                      sortable: true,
                                      format: (row) => <Text>{row.nbANull}</Text>
                                    }
                                  ]}
                                  data={this.state.admin.stats.raw}
                                  noHeader
                                  highlightOnHover
                                  pagination
                                  dense
                                  paginationComponentOptions={{
                                    rowsPerPageText: 'Lignes par page:',
                                    rangeSeparatorText: 'parmi',
                                    selectAllRowsItem: true,
                                    selectAllRowsItemText: 'Tout',
                                  }}
                                  onRowClicked={(row, event) => this.showPie(row.nbQuestion)}
                                />
                              </View>
                            ) : (
                              <View style={[styles.center]}>
                                <Spinner size='giant' />
                              </View>
                            )}
                          </Row>
                        ) : (
                          <Row size={1}>
                            {(!this.state.isLoading) ? (
                              <View style={[styles.center]}>
                                <Button
                                  size='small'
                                  status='basic'
                                  icon={(style) => (<Icon {...style} name='arrow-back-outline' fill='black' />)}
                                  onPress={() => this.setState(prevState => ({ admin: { ...prevState.admin, isPie: false } }))}
                                  style={{ position: 'absolute', top: 30, left: 0 }}>
                                  Retours
                                </Button>
                                <Text category='h5'>Question n°{this.state.admin.idPie}</Text>
                                <PieChart
                                  data={this.state.admin.stats.pie[this.state.admin.idPie - 1]}
                                  width={480}
                                  height={300}
                                  chartConfig={{
                                    color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                                    barPercentage: 0.5
                                  }}
                                  accessor={"nbA"}
                                  backgroundColor={"transparent"}
                                  paddingLeft={"15"}
                                  style={{ borderRadius: 15, shadowColor: "#000", shadowOffset: { width: 0, height: 5, }, shadowOpacity: 0.34, shadowRadius: 6.27, elevation: 10 }}
                                //absolute
                                />
                              </View>
                            ) : (
                              <View style={[styles.center]}>
                                <Spinner size='giant' />
                              </View>
                            )}
                          </Row>
                        )}
                      </Grid>
                    </Layout>
                  )}
                </Modal>
              </Layout>
            ) : (
              <Layout style={[styles.container, styles.center]} level='4'>
                <Text appearance='hint'>Aucun examen créé</Text>
                <Text>{'\n'}</Text>
                <Button
                  appearance='outline'
                  size='small'
                  icon={(style) => (<Icon {...style} name='plus' />)}
                  onPress={() => this.setState(prevState => ({ admin: { ...prevState.admin, overlayIsVisible: true } }))}>
                  Créer un examen
                </Button>
                <Modal
                  visible={this.state.admin.overlayIsVisible}
                  style={[{ height: (this.state.admin.isStats) ? 800 : 600, width: (this.state.admin.isStats) ? 700 : 500 }]}
                  backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                  onBackdropPress={() => this.setState(prevState => ({ admin: { ...prevState.admin, overlayIsVisible: false, isStats: false, isPie: false } }))}>
                  <Layout style={[styles.container, styles.center]}>
                    <Grid>
                      <Row size={1}>
                        <Layout style={[styles.container, styles.center]}>
                          <Text category='h5'>Ajouter un examen</Text>
                        </Layout>
                      </Row>
                      <Row size={2}>
                        <Layout style={[styles.container]}>
                          <Input
                            placeholder="Titre de l'examen"
                            icon={(style) => (<Icon {...style} name='hash' />)}
                            onChangeText={text => this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examTitle: text } } }))}
                            disabled={this.state.isLoading}
                          />
                          <Text>{"\n"}</Text>
                          <View style={[{ flexDirection: 'row', flexWrap: 'wrap' }, styles.center]}>
                            <Text style={{ fontWeight: "bold" }}>Groupe: {this.state.admin.add.examGroupe + '\t'}</Text>
                            <Button
                              style={{ height: 40, borderTopRightRadius: 0, borderBottomRightRadius: 0, marginRight: -1 }}
                              appearance='outline'
                              icon={(style) => (<Icon {...style} name='minus' />)}
                              onPress={() => {
                                if (this.state.admin.add.examGroupe >= 2) {
                                  this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examGroupe: this.state.admin.add.examGroupe - 1 } } }))
                                }
                              }}
                              disabled={this.state.isLoading}
                            />
                            <Button
                              style={{ height: 40, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                              appearance='outline'
                              icon={(style) => (<Icon {...style} name='plus' />)}
                              onPress={() => {
                                if (this.state.admin.add.examGroupe <= 19) {
                                  this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examGroupe: this.state.admin.add.examGroupe + 1 } } }))
                                }
                              }}
                              disabled={this.state.isLoading}
                            />
                          </View>
                          <Text>{"\n"}</Text>
                          <View style={[{ flexDirection: 'row', flexWrap: 'wrap' }, styles.center]}>
                            <Grid>
                              <Row size={3} style={[styles.center, { marginBottom: 10 }]}>
                                <Text style={{ fontWeight: "bold" }}>Nombre de questions:</Text>
                              </Row>
                              <Row size={1}>
                                <Input
                                  placeholder="Nombre de questions"
                                  value={this.state.admin.add.examNbQuestions}
                                  onChangeText={text => {
                                    if (parseInt(text)) {
                                      if (parseInt(text) >= 1 && parseInt(text) <= 300) {
                                        this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examNbQuestions: parseInt(text) } } }));
                                      }
                                    } else {
                                      this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examNbQuestions: 1 } } }));
                                    }
                                  }}
                                  disabled={this.state.isLoading}
                                />
                                <Button
                                  style={{ height: 40, borderTopRightRadius: 0, borderBottomRightRadius: 0, marginRight: -1 }}
                                  appearance='outline'
                                  icon={(style) => (<Icon {...style} name='minus' />)}
                                  onPress={() => {
                                    if (this.state.admin.add.examNbQuestions >= 2) {
                                      this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examNbQuestions: parseInt(this.state.admin.add.examNbQuestions) - 1 } } }))
                                    }
                                  }}
                                  disabled={this.state.isLoading}
                                />
                                <Button
                                  style={{ height: 40, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                  appearance='outline'
                                  icon={(style) => (<Icon {...style} name='plus' />)}
                                  onPress={() => {
                                    if (this.state.admin.add.examNbQuestions <= 299) {
                                      this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examNbQuestions: parseInt(this.state.admin.add.examNbQuestions) + 1 } } }))
                                    }
                                  }}
                                  disabled={this.state.isLoading}
                                />
                              </Row>
                            </Grid>
                          </View>
                          <Text>{"\n"}</Text>
                          <Input
                            placeholder="Réponses justes"
                            icon={(style) => (<Icon {...style} name='list' />)}
                            onChangeText={text => this.setState(prevState => ({ admin: { ...prevState.admin, add: { ...prevState.admin.add, examAnswers: text } } }))}
                            disabled={this.state.isLoading}
                          />
                        </Layout>
                      </Row>
                      <Row size={1}>
                        <Layout style={[styles.container, styles.center]}>
                          {(!this.state.isLoading) ? (
                            <Button
                              style={{ marginTop: -15 }}
                              appearance='outline'
                              icon={(style) => (<Icon {...style} name='checkmark-circle-outline' />)}
                              onPress={() => this.actionOnExam('createExam', null)}
                              disabled={this.state.isLoading}>
                              Créer
                            </Button>
                          ) : (
                            <Spinner size='giant' />
                          )}
                        </Layout>
                      </Row>
                    </Grid>
                  </Layout>
                </Modal>
              </Layout>
            )
            }
          </ApplicationProvider>
        );
      } else {
        return (
          <ApplicationProvider mapping={mapping} theme={basicTheme}>
            <IconRegistry icons={EvaIconsPack} />
            <NotificationContainer />
            <TopNavigation
              title='Qexam'
              subtitle='Admin panel'
              style={{ backgroundColor: 'rgb(51, 102, 255)' }}
              titleStyle={{ color: '#fff' }}
              subtitleStyle={{ color: '#C5CEE0' }}
              alignment='center'
              leftControl={<TopNavigationAction
                icon={(style) => (<Icon {...style} name='home-outline' fill='#fff' />)}
                onPress={() => this.goBackHome()} />}
            />
            <Layout style={[styles.container, styles.center]} level='4'>
              <Spinner size='giant' />
            </Layout>
          </ApplicationProvider >
        );
      }
    } else if (this.state.page == 'exam') {
      return (
        <ApplicationProvider mapping={mapping} theme={basicTheme}>
          <IconRegistry icons={EvaIconsPack} />
          <NotificationContainer />
          <Layout style={styles.container}>
            <Grid>
              <Row size={1} />
              <Row size={3}>
                <Layout style={[styles.container, styles.center]}>
                  <Text category='h4'>Question n°{this.state.exam.question}</Text>
                </Layout>
              </Row>
              <Row size={2}>
                <Layout style={[styles.container]}>
                  <RadioForm
                    style={styles.center}
                    ref={answerForm}
                    radio_props={[
                      { label: 'Réponse A\n', value: 'A' },
                      { label: 'Réponse B\n', value: 'B' },
                      { label: 'Réponse C\n', value: 'C' },
                      { label: 'Réponse D\n', value: 'D' }
                    ]}
                    initial={-1}
                    labelStyle={{ fontSize: 20, marginTop: -3, marginStart: 10 }}
                    animation={false}
                    onPress={(value) => this.setState({ exam: { nbQuestions: this.state.exam.nbQuestions, question: this.state.exam.question, answer: value } })}
                  />
                </Layout>
              </Row>
              <Row size={2}>
                <Layout style={[styles.container, styles.center]}>
                  {(!this.state.isLoading) ? (
                    <Button
                      icon={(style) => (<Icon {...style} name='paper-plane' />)}
                      onPress={() => this.submit()}
                      disabled={this.state.isLoading}>
                      Valider
                    </Button>
                  ) : (
                    <Spinner size='giant' />
                  )}
                </Layout>
              </Row>
              <Row size={1} />
            </Grid>
            <View style={{ position: 'absolute', top: 20, right: 20 }}>
              <Button
                appearance='ghost'
                size='giant'
                icon={(style) => (<Icon {...style} name='refresh' fill='orange' />)}
                onPress={() => this.setState(prevState => ({ exam: { ...prevState.exam, answer: 'o' } }))}
              />
            </View>
            <View style={{ position: 'absolute', top: 20, left: 20 }}>
              <Text category="h5">{this.state.lastname + '\n' + this.state.firstname}</Text>
            </View>
          </Layout>
        </ApplicationProvider>
      );
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center'
  },
});
