module.exports = {
    median: function (a) {
        a = this.getNotesArray(a);
        a = a.slice(0).sort(function (x, y) {
            return x - y;
        });
        var b = (a.length + 1) / 2;
        return (a.length % 2) ? a[b - 1] : (a[b - 1.5] + a[b - 0.5]) / 2;
    },
    average: function (a) {
        a = this.getNotesArray(a);
        return Math.round(a.reduce((accumulator, currentValue) => accumulator + currentValue) / a.length * 100) / 100;
    },
    getNotesArray: function (a) {
        var na = [];
        for (const i in a) {
            na.push(a[i].note);
        }
        return na;
    }
}